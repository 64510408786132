<template>
  <div>
    <div class="flex flex-col items-center w-16 lg:w-48">
      <div class="relative ">
        <router-link class="step" :class="step === 1 ? 'bg-secondary secondary' : 'bg-primary primary'" :to="link">
          01
        </router-link>
        <span class="absolute left-0 my-auto inset-y-0 ml-8 h-2 w-8 lg:w-24"
          :class="step === 1 ? 'bg-secondary' : 'bg-primary'"></span>
      </div>
      <p class="step_text hidden lg:block">Dados da empresa</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['step', 'link']
}
</script>

<style lang="sass" scoped>

.step
  @apply relative z-20 w-8 h-8 rounded-full text-white text-xs flex justify-center items-center
  &:focus
    @apply outline-none

.step_text
  @apply w-20 h-8 text-gray-700 mt-2 text-center text-xss

.router-link-exact-active
  @apply border-2 border-white
  &.primary::after
    content: ''
    @apply absolute border-2 border-primary rounded-full
    height: 2.2rem
    width: 2.2rem
  &.secondary::after
    content: ''
    @apply absolute border-2 border-secondary rounded-full
    height: 2.2rem
    width: 2.2rem

</style>
