<template>
  <div>
    <div class="flex flex-col items-center w-16 lg:w-48">
      <div class="relative ">
      <router-link class="step" :class="step === 4 ? 'bg-secondary secondary' : step > 4 ? 'bg-primary primary' : 'bg-gray-500 gray'" :to="link">
        04
      </router-link>
      <span class="absolute right-0 my-auto inset-y-0 mr-4 ml-4 h-2 w-8 lg:w-24" :class="step === 4 ? 'bg-secondary' : step > 4 ? 'bg-primary' : 'bg-gray-500'"></span>
      </div>
      <p class="step_text hidden lg:block">Documentos</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['step', 'link']
}
</script>

<style lang="sass" scoped>

.step
  @apply relative z-20 w-8 h-8 rounded-full text-white text-xs flex justify-center items-center
  &:focus
    @apply outline-none

.step_text
  @apply w-20 h-8 text-gray-700 mt-2 text-center text-xss

.router-link-exact-active
  @apply border-2 border-white
  &.primary::after
    content: ''
    @apply absolute border-2 border-primary rounded-full
    height: 2.2rem
    width: 2.2rem
  &.secondary::after
    content: ''
    @apply absolute border-2 border-secondary rounded-full
    height: 2.2rem
    width: 2.2rem
  &.gray::after
    content: ''
    @apply absolute border-2 border-gray-500 rounded-full
    height: 2.2rem
    width: 2.2rem

</style>
