<template>
  <div>
    <div class="content_solicitation" v-if="solicitations.length === 1 && solicitations[0].status === 'Pré-Aprovado'">
      <p class="title">Ei, você tem uma solicitação pré-aprovada</p>
      <div class="cards">
        <div class="card">
          <div class="card_content">
            <div class="line_vertical line_vertical_pending"></div>
            <div class="ml-4">
              <p class="card_title" >Valor:</p>
              <p class="card_value">{{solicitations[0].valorSolicitado | money}}</p>
              <div class="flex mt-1">
                <span class="card_status card_status_pending">Pré-Aprovado</span>
                <button class="card_link" v-if="solicitations[0].condicaoAprovada != null && solicitations[0].condicaoAprovada.valor > 0 &&  !solicitations[0].todosSociosAssinaram" @click="$router.push('/tomador/aceitar-condicoes/id')">Aceitar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 h-full lg:h-24 lg:pl-6 w-full lg:w-2/3">
          <p class="card_text text-center lg:text-left font-bold">Vi que você é novo por aqui!</p>
          <ul class="card_text text-center lg:text-left mt-4">
            <li class="hidden lg:inline-block">Clique na solicitação à esquerda para aprovar ou recusar a proposta.</li>
            <li class="lg:hidden">Clique na solicitação acima para aprovar ou recusar a proposta.</li>
            <li>Vale lembrar que é preciso completar o cadastro para aprovar a proposta.</li>
            <li>Acompanhe as etapas do cadastros, logo acima.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content_solicitation" v-else>
      <p class="title">Suas últimas solicitações de empréstimo</p>
      <div class="cards">
        <div class="card" v-for="solicitation in solicitations" :key="solicitation.id">
          <div class="card_content">
            <div class="line_vertical" :class="`line_vertical_${bgStatus(solicitation.status || 'Pré-Aprovado')}`"></div>
            <div class="ml-4">
              <div v-if="!solicitation.condicaoAprovada">
                <p class="card_title" >{{(solicitation.emprestimos.length ? 'Valor concedido' : 'Valor solicitado')}}</p>
                <p class="card_value" v-if="solicitation.emprestimos.length">{{solicitation.emprestimos.map(x=>x.valorFinanciado).reduce((x,y) => x + y) | money}}</p>
                <p class="card_value" v-else-if="!solicitation.emprestimos.length">{{solicitation.valorSolicitado | money}}</p>
              </div>

              <div v-else>
                <p class="card_title" >Solicitado: {{solicitation.valorSolicitado | money}} </p>
                 <p class="card_title" >Valor aprovado pelo comitê:</p>
                <p class="card_value" v-if="!solicitation.emprestimos.length">{{solicitation.condicaoAprovada.valor | money}}</p>
                          </div>
                 <p class="card_title" >Em {{solicitation.condicaoAprovada.prazo}}x - Taxa: {{solicitation.condicaoAprovada.taxa.toString().replace('.',',')}}% a.m </p>

              <div class="flex mt-1">
                <span class="card_status" :class="`card_status_${bgStatus(solicitation.status || 'Pré-Aprovado')}`">
                   <p class="card_status_text">{{solicitation.status || 'Em Análise'}}</p>
                </span>
                <button class="card_link"  v-if="solicitation.status === 'Pré-Aprovado' && solicitation.condicaoAprovada != null && solicitation.condicaoAprovada.valor > 0 && !solicitation.todosSociosAssinaram">Aceitar</button>
                <button class="card_link" v-else-if="solicitation.status === 'Concedido'" @click="$router.push(`/tomador/solicitacao/${solicitation.id}`)">Ver Detalhes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Solicitation from '@/services/Business/Solicitation'
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'solicitations',
  mixins: [MoneyFilter],
  data () {
    return {
      solicitations: [],
      showModal: false
    }
  },
  async mounted () {
    this.solicitations = (await Solicitation.getSolicitations()).data.data.sort((a, b) => new Date(b.dataSolicitacao) - new Date(a.dataSolicitacao))
  },
  methods: {
    bgStatus  (status) {
      switch (status) {
        case 'Pré-Aprovado':
          return 'pending'
        case 'Aprovado pelo comitê':
          return 'approved'
        case 'Negado':
          return 'recused'
        case 'Em Rodada':
          return 'approved'
        case 'Concedido':
          return 'approved'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.content_solicitation
  @apply w-full bg-white flex flex-col items-center p-6 border shadow rounded-lg

.title
  @apply font-medium text-xl text-black text-center uppercase

.cards
  @apply flex flex-col justify-center w-full
  @screen lg
    @apply flex-row flex-wrap w-full justify-start

.card
  @apply flex justify-center mt-6 h-24 relative w-full
  @screen lg
    @apply w-1/3

.line_vertical
  @apply w-1 absolute
  height: 90%
  &_pending
    @apply bg-yellow-500
  &_recused
    @apply bg-red-600
  &_approved
    @apply bg-primary

.card_title
  @apply leading-none text-xss text-gray-700 mt-1

.card_value
  @apply text-2xl text-gray-700 font-semibold opacity-75

.card_status
  @apply flex items-center w-full py-1 mr-6 rounded-full px-2
  &_pending
    @apply bg-yellow-500
  &_recused
    @apply bg-red-600
  &_approved
    @apply bg-primary
  &_text
    @apply text-white text-xss text-center whitespace-no-wrap w-20

.card_link
  @apply text-xs text-gray-700 underline
  &:focus
    @apply outline-none

.card_text
  @apply text-xs text-gray-700 leading-normal
</style>
