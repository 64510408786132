<template>
  <div>
    <div>
      <div class="flex w-full justify-center mt-12">
        <step1 :step="step" :link="`${url}dados-empresa`" />
        <step2 :step="step" :link="($store.state.business.step >= 2 ? `${url}dados-pessoais` : '#')" />
        <step3 :step="step" :link="($store.state.business.step >= 3 ? `${url}quadro-socios` : '#')" />
        <step4 :step="step" :link="`${url}documentos`" />
      </div>
    </div>
    <div v-if="$store.state.business.status && $store.state.business.status != 'NEGADO'"
      class="flex w-full justify-center mt-12">Status da sua
      solicitação: {{ $store.state.business.status }}
    </div>
  </div>

</template>

<script>
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Dashboard from '@/services/Business/StatusManager'
import StatusManager from '../../services/Business/StatusManager'

export default {
  props: {
    step: {
      required: false
    },
    url: {
      required: false,
      default: ''
    }
  },
  name: 'steps',
  components: { Step1, Step2, Step3, Step4 },
  data() {
    return {
      modal: false,
      stepOpen: 0,
      statusEmpresa: null
    }
  },
  mounted: async function () {
    console.log("Entrou")
    await StatusManager.refresh();
  }
}

</script>

<style lang="sass" scoped>
</style>
