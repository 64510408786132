<template>
  <div>
    <div class="content">
      <p class="title" v-show="!$store.state.business.incompleteProfile">Seu cadastro está completo :)</p>
      <p class="title" v-show="$store.state.business.incompleteProfile">Vamos completar seu cadastro?</p>
      <p class="mt-2 subtitle text-center" v-show="$store.state.business.incompleteProfile">Para finalizar a análise de
        crédito da sua
        empresa precisamos que você complete as 4 etapas abaixo.<br /> Se precisar de ajuda pode nos chamar no WhatsApp
        <a style="text-decoration: underline"
          href="https://api.whatsapp.com/send/?phone=5511971981762&text&app_absent=0">: (11) 97198-1762</a>
      </p>
      <div class="flex w-full justify-center">
        <profile-stepper class="w-full" url="/tomador/perfil/" :step="$store.state.business.step" />
      </div>
      <br />
      <hr />
      <p class="subtitle yellow" v-show="$store.state.business.docsEmAnalise">Seus documentos estão em análise.</p>

    </div>
    <solicitations />
  </div>
</template>

<script>
import Solicitations from '@/views/Business/Solicitation/Solicitations'
import ProfileStepper from '@/components/ProfileStepper/ProfileStepper'

export default {
  name: 'business-overview',
  components: { Solicitations, ProfileStepper }
}
</script>

<style lang="sass" scoped>

.title
  @apply font-medium text-xl text-black text-center uppercase

.content
  @apply w-full flex flex-col items-center bg-white p-6 mb-8 border shadow rounded-lg

</style>
